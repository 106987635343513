import "../footer/footer.scss";

export const Footer = () => {
  return (
    <div className="footer">
      <div className="line"></div>
        <div className="footer-statements">
          <ol>
            <li>
              מדיניות הפרטיות ותנאי ההשתתפות עשויים להשתנות מעת לעת. עדכונים תוכל
              למצוא ב <a href="https://www.teva.co.il/" target="_blank" title="teva-website" aria-label="teva-website">teva.co.il</a>
            </li>
            <li>
              "טבע" הינה חברת תרופות שכתובתה הראשית היא דבורה הנביאה 124, תל אביב,
              ישראל. במסמך זה, "טבע" משמע חברת טבע תעשיות פרמצבטיות בע"מ וחברות
              קשורות שלה. טבע תעשיות פרמצבטיות בע"מ היא בעלת הרישום בתכשיר AJOVY<sup>®</sup>
              והשירות ניתן מטעמה ומסופק על-ידי אחים ו/או אחיות, עובדי חברת טבע.
            </li>
            <li>בישראל או מחוצה לה.</li>
          </ol>
          <div className="accessibility" aria-label="accesibility-statement">
            <a href="https://www.teva.co.il/about-teva/accessibility-statement/" target="_blank" title="accessibility-statement" aria-label="accessibility-statement">
              <img src={require('../../assets/images/negishut_icon.png')} alt="accessibility-icon"/>
            </a>
            <a href="https://www.teva.co.il/about-teva/accessibility-statement/" target="_blank" title="accessibility-statement" aria-label="accessibility-statement">
              הצהרת נגישות
            </a>
          </div>
      </div>
    </div>
  );
};
