import "./inputStyle.scss";
import { FormConig } from "../../interfaces";

interface InputProps {
  inputAttribute: FormConig;
  onChange: (e: any) => void | undefined;
  onKeyDown: (e: any) => void | undefined | any;
}

export const Input = ({ inputAttribute, onChange, onKeyDown }: InputProps) => {

  return (
    <div className="form-element">
      <label htmlFor={inputAttribute.name}>
        {inputAttribute.label}
        {inputAttribute.isRequired ? '*' : ''}
      </label>

      <input
        className="form-input"
        name={inputAttribute.name}
        id={inputAttribute.name}
        type={inputAttribute.type}
        aria-label={`your-${inputAttribute.name}`}
        onChange={(e) => onChange(e.target.value)}
        onKeyDown={(e) => onKeyDown(e)}
        maxLength={
          inputAttribute.maxLength ? inputAttribute.maxLength : undefined
        }
        minLength={
          inputAttribute.minLength ? inputAttribute.minLength : undefined
        }
        max={inputAttribute.max ? inputAttribute.max : ""}
      />
      {inputAttribute.errorMsg && (
        <label htmlFor={inputAttribute.name}>
          <span className="invalid-feedback"> {inputAttribute.errorMsg}</span>
        </label>
      )}
    </div>
  );
};
