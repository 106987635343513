import "./treatmentProgramStyle.scss";
import { TreatmentProgramFields } from "../constants";
import { checkValidation } from "../utils";
import { FormConig } from "../interfaces/index";
import { useState } from "react";
import { Header } from "../components/header/header";
import { Footer } from "../components/footer/footer";
import { Form } from "../components/index";

declare const window: any;

export const TreatmentProgramForm = () => {
  const [inputAttributes, setFormConig] = useState<FormConig[]>(
    TreatmentProgramFields
  );

  const onFormChange = (value: any, attribute: FormConig) => {
    // sending event to GTM
    if (attribute.beenFilled === false) {
      window.dataLayer.push({'Category':'form','Action':'form interaction','Label': attribute.name,'event':'auto_event'});
      attribute.beenFilled = true;
    }

    const updatedAttribute = inputAttributes.map((input) => {
      if (input.id === attribute.id) {
        const isValidText = checkValidation(input, value);
        if (isValidText) {
          input.errorMsg = isValidText;
        } else {
          input.errorMsg = "";
        }
      }
      return input;
    });
    setFormConig(updatedAttribute);
  };

  return (
    <main>
      <Header />
      <h1>
        <span className="header-text">מדיניות פרטיות, תנאי השתתפות תכנית תמיכת מטופלים והסכמת מטופל</span>
        <sup>1</sup>
      </h1>
      <div className="page-text">
        <p>
          חברת טבע, נותנת השירות, רואה חשיבות בסיוע למטופלים הסובלים ממיגרנה.
          תכנית ה- "AJOVY<sup>®</sup>" (<b>להלן – "התוכנית"</b>) הינה תכנית שנוצרה, ממומנת
          ומבוצעת על ידי טבע<sup>2</sup> ומאושרת על ידי משרד הבריאות. תפקידה
          לתמוך, להדריך ולהנחות מטופלים הסובלים ממיגרנה שנרשם להם, על-ידי
          רופאיהם, התכשיר AJOVY (Fremanezumab)<sup>®</sup> (להלן - AJOVY<sup>®</sup>) על-ידי רופאיהם,
          כחלק מהטיפול בהם.
        </p>
        <div>
          <p>
            מטרת התוכנית לספק למטופלים שנרשם להם AJOVY<sup>®</sup> את התמיכה וההנחיה
            הנדרשים בקשר עם הנטילה של והטיפול ב- AJOVY<sup>®</sup> ,וזאת על מנת להקל על
            המטופלים לקיים את הטיפול.
          </p>

          <b>
            לתשומת ליבך, התכנית לא נועדה להחליף או לשנות באופן כלשהו את הטיפול
            הרפואי הקיים, הייעוץ הרפואי, הוראות הנטילה או כל סיוע אחר שאת/ה
            מקבל/ת מהרופא שלך.
          </b>
          <p>
            השירותים שיוצעו לך במסגרת התוכנית (<b>"שירותי התוכנית"</b>) הינם:
          </p>
        </div>
        <div>
          <ul>
            <li>
              הדרכה על-ידי צוות מקצועי ומיומן (אח/אחות) ביחס לאופן ההזרקה של
              AJOVY<sup>®</sup>;
            </li>
            <li>
              הצוות המקצועי עשוי ליצור אתך קשר באמצעות שיחות טלפון, הודעות SMS,
              דואר אלקטרוני מעת לעת, ולאחר קבלת אישורך וביידוע מראש, לצורך
              תמיכה, במידה ותידרש, בהקשר לטיפול ב- AJOVY<sup>®</sup>. תדירות יצירת הקשר
              עמך תיקבע בהתאם לבקשתך ולצרכיך;
            </li>
            <li>מסירת מידע נוסף לגבי התרופה AJOVY<sup>®</sup>;</li>
          </ul>
        </div>
        <div>
          <b>מובהר כי אינך חייב/ת למסור לנו את הסכמתך או להצטרף לתכנית.&nbsp;</b>
          במידה ובחרת להצטרף לתוכנית, הנך רשאי/ת לבחור לעזוב את התכנית בכל עת
          ולא תידרש/י לספק הסבר כלשהו להחלטתך זו.
        </div>
        <div>
          <p>
            במהלך ולצורך מתן שירותי התוכנית וניהולה, טבע תידרש לתעד חלק מהמידע
            שיימסר על ידך. לא חלה עליך חובה חוקית למסור את המידע ומסירתו תלויה
            בהסכמתך. במקרה של דיווח על תופעת לוואי, ואם הדבר יידרש במפורש על-ידי
            משרד הבריאות, טבע תהא רשאית להעביר מידע מזוהה שלך למחלקת מעקב תרופתי
            בטבע<sup>3</sup> ובמידת הצורך לפנות בעניין זה אל הרופא המטפל שלך.
          </p>
        </div>
        <div>
          במסמך זה אנו מבקשים להביא לידיעתך מהו המידע שיתועד וכיצד טבע תנהג בו.
          על מנת להירשם לשירותי התוכנית, עליך לקרוא בתשומת לב את המידע במסמך זה
          ולחתום על טופס <b>זה</b>. בנוסף, ייתכן שבאמצעות ניתוח של כל המידע שטבע
          תאסוף או נקבל, כולל מידע לגבי המשתמשים, אנו עשויים ליצור מידע סטטיסטי
          אודות מגוון רחב של משתמשים (להלן: <b>"מידע סטטיסטי"</b>). מידע סטטיסטי
          מסייע לנו בהבנת מגמות וצרכיהם של המשתמשים בכדי שנוכל לשקול שירותים
          ומוצרים חדשים ובכדי שנוכל להתאים שירותים ומוצרים קיימים לצרכיהם של
          המשתמשים. מידע סטטיסטי הינו אנונימי ולא נקשר אותו למידע אישי. ייתכן
          שנמסור מידע סטטיסטי לשותפים שלנו, ללא הגבלה, לפי תנאים מסחריים שנקבע
          לפי שיקול דעתנו הבלעדי.
        </div>
        <div>
          <h2 className="title">
            המידע שתתבקש/י למסור על מנת להשתתף בתוכנית, ולצורך דיווח תופעות לוואי
            אפשריות, הינו כדלקמן:
          </h2>
          <ul>
            <li>שם ומספר ת.ז;</li>
            <li>גיל ותאריך לידה במידה ואת/ה מוכן/ה למסור;</li>
            <li>מין (ז/נ);</li>
            <li>
              פרטי קשר (טלפון, כתובת, דואר אלקטרוני, זמן מיטבי ליצירת קשר);
            </li>
            <li>שם רופא ומוסד רפואי;</li>
            <li>
              יובהר כי מסירת מידע היא מרצונך החופשי ובהסכמתך אולם מסירת המידע
              נחוצה כדי שאספקת שירותי התכנית תתאפשר.
            </li>
            <li>
              בנוסף, המידע שלהלן עשוי להיות מתועד ובכפוף להסכמתך, או להוראת כל
              דין או בית משפט מוסמך, למסור אותו: טבע תשתף פעולה עם רשויות אכיפת
              החוק או כל צו שיפוטי הדורש או מנחה אותנו לגלות את פרטיך. טבע עשויה
              להשתמש בשירותיהם של קבלני משנה הנמצאים במדינות אשר אינן מדינת
              מגוריך.
            </li>
            <li>
              פרטים בקשר למינון תרופת ה- AJOVY<sup>®</sup> הרשומה לך ואופן נטילת התרופה;
            </li>
            <li>
              פרטים הנראים לך רלוונטיים לטיפול ב- AJOVY<sup>®</sup> בהקשר אליך, כגון מידת
              היענותך לטיפולים בעבר, או כל סוגיה אחרת הנראית לך רלוונטית להגברת
              היענותך לטיפול ו/או רצונך להגברת המודעות למחלה ואשר תעלה בפנינו;
            </li>

            <li>
              מידע שמסרת במענה לשאלוני רמת ההיענות שניתנו על ידי צוות ההדרכה של
              טבע, כגון התדירות שבה את/ה שוכח/ת ליטול תרופות או האם אי פעם בחרת
              שלא ליטול את תרופותיך;
            </li>
            <li>
              פרטים על השיחות והפגישות שקיימת עם צוות ההדרכה המקצועי מטעם טבע;
            </li>
            <li>
              מידע על תופעות לוואי ו/או על חשש לפגם איכות בתרופה ובקשות למידע
              ולשירותים הקשורים בתרופה AJOVY<sup>®</sup> בלבד;
            </li>
          </ul>
        </div>

        <div>
          לתשומת לבך, טבע עשויה להיות
          מחויבת על-פי דין להעביר מידע על אודות תופעות לוואי או תלונות
          הקשורות לטיפול בתרופה AJOVY<sup>®</sup> למשרד הבריאות. ככל שיימסר על ידך
          מידע בהקשר זה, בחתימתך על מדיניות פרטיות זו, הנך מסכים/מה לתיעודו
          של מידע זה, ולהעברתו אל רשויות הבריאות הרלבנטיות, במידת הצורך.
          יובהר כי במידה והדבר יידרש על-ידי משרד הבריאות במפורש, טבע תידרש
          להעביר מידע מזוהה למחלקת מעקב תרופתי בטבע ובמידת הצורך לפנות
          בעניין זה אל הרופא המטפל שלך המידע שיתועד על-ידי טבע ישמש כדי לספק
          לך את שירותי התוכנית וכן על מנת להעריכה ולשפרה במידת הצורך. למען
          הסר ספק, המידע שיועבר לרשויות הבריאות לא יאפשר לזהות אותך
        </div>

        <div>
          <h2 className="title">שמירת המידע שיתועד במסגרת התוכנית ועדכונו</h2>
          <p>
            במהלך השתתפותך בתכנית, המידע המתועד נשמר במאגר נתונים ייעודי שמספרו
            1070445. המידע האמור נגיש אך ורק לצוות האחיות של טבע, לגורמים
            המספקים את שירותי התוכנית ו/או שירותי תמיכה טכנית ותחזוקה של המאגר.
            יובהר כי טבע תהא רשאית להתקשר בעתיד עם ספקי שירות (לרבות כאלה
            שממוקמים מחוץ לישראל) לצורך מתן שירותי התוכנית, וכן לצורך אחסון
            ותמיכה טכנית ואדמיניסטרטיבית ביחס למאגר הנתונים והתקשורת מול
            המטופלים, תוך שמירת מלוא הזכויות המוקנות לך על פי דין.
          </p>
        </div>
        <div>
          <h2 className="title">אבטחה</h2>
          <p>
            אנו נוקטים באמצעים המקובלים בתעשייה על מנת להגן על המידע שנמסר לנו,
            גם בזמן המסירה וגם לאחר שאנו מקבלים אותו. אך, לא קיימות שיטות העברה
            או אחסון אלקטרוני שהינן בטוחות לחלוטין. לכן, למרות שאנו משתדלים
            לנקוט בדרכים מקובלות בכדי להגן על המידע שלך, איננו יכולים להבטיח את
            ביטחונו המוחלט של המידע.
          </p>
          <p>
            אנחנו נמחק כל מידע אישי שקיבלנו ממשתמש לאחר קבלת דרישה בכתב מאותו
            משתמש. לא נוכל לשחזר מידע שנמחק.
          </p>
          <p>
            מידע מועבר באופן מאובטח באמצעות הצפנת SSL ומאוחסן בשרתים מאובטחים.
            בנוסף, אנחנו נוקטים באמצעי אבטחת מידע נוספים כגון בקרת גישה, אמצעי
            אבטחה פיזיים קפדניים ושיטות בטוחות לאיסוף, אחסון ועיבוד מידע, על פי
            הדין.
          </p>
        </div>
        <div>
          <h2 className="title">עיון, תיקון או עדכון של המידע שלך</h2>
          <p>
            טבע מאפשרת לעיין במידע שסיפקת ולשנות נתונים, אי דיוקים או שגיאות
            במידע. כדי לבקש לעיין במידע או לתקן ולעדכן את המידע האישי שלך, צור
            עמנו קשר בכתובת&nbsp;
            <a
              data-linkto="mailto:"
              href="mailto:PSPTevaIsrael@tevapharm.com?subject="
              title="Teva Email"
            >
            PSPTevaIsrael@tevapharm.com
            </a>
          </p>
        </div>
        <div>
          <h2 className="title">פרטי קשר</h2>
          <p>
            כאמור לעיל, הצוות האחיות עשוי ליצור אתך קשר מעת לעת בהסכמתך לצורך
            יישומה של התוכנית. במקרה שלא היית זמין לפניה שנעשתה אליך והושארה לך
            הודעה, או אם בזמן כלשהו מתעוררות אצלך שאלות או תהיות בנוגע למדיניות
            פרטיות זו, אנא צור/צרי קשר עם אחות טבע. טבע תעשה כל שניתן במסגרת
            האמצעים הסבירים על מנת לענות על שאלותיך במהירות או לפתור את בעיותיך.
          </p>
          <p>שם האחות: יפעת קולה</p>
          <p>מספר טלפון ליצירת קשר: 054-8884033</p>
          <p>
            נא ליצור קשר בימים א' עד ה' בשעות העבודה המקובלות, בין 08:00
            ל-17:00. הודעה שהושארה מחוץ לשעות העבודה תענה ביום העבודה הקרוב הבא.{" "}
          </p>
        </div>
      </div>

      <Form
        emailTitle="התקבל קונסנט חדש לתכנית מטופלים אג׳ובי"
        inputAttributes={inputAttributes}
        onFormChange={(e, attribute) => onFormChange(e, attribute)}
      />
      <Footer />
    </main>
  );
};
