import "./formStyle.scss";
import { FormEvent, useRef, useState } from "react";
import { CheckedCondition } from "../conditions/checkedCondition";
import { openingMailText } from '../../constants/index';
import { FormConig } from "../../interfaces";
import { Condition } from "../conditions/condition";
import { Input } from "../index";
import axios from "axios";
import moment from "moment";
import 'moment-timezone';

type FormProps = {
  emailTitle: string;
  emailText?: string;
  inputAttributes: FormConig[] | [];
  onFormChange: (e: any, attribute: FormConig) => void | undefined;
};

declare const window: any;

export const Form = ({
  emailTitle,
  emailText = "",
  inputAttributes,
  onFormChange,
}: FormProps) => {
  const [confirmConditions, setConfirmConditions] = useState(false);
  const [validForm, setValidForm] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const theForm = useRef<HTMLFormElement>(null);
  const apiUrl = process.env.REACT_APP_API_GATEWAY;

  const checkFormValidation = async (
    validFields: boolean,
    form: FormEvent<HTMLFormElement>
  ) => {
    if (validFields) {
      let formData: any = {};
      inputAttributes.map((input) => {
        if (form.currentTarget[input.name].value) {
          if (input.name === 'birthday') {
            let birth_date_arr  = (form.currentTarget[input.name].value).split('-').reverse();
            let birth_date = birth_date_arr.join('-');
            return formData[input.label] = birth_date;
          } else {
            return formData[input.label] = form.currentTarget[input.name].value;
          }
        }
      });
      setErrorMsg("");
      try {
        setIsLoader(true);

        let innerMailText = openingMailText.replace('$name',formData['שם מלא']);
        innerMailText = innerMailText.replace('$date', 
          moment(new Date()).tz('israel').format('HH:mm:ss DD/MM/YYYY'));
        emailText = innerMailText;

        const response = await axios.post(apiUrl!, {
          emailParams: "/ajovi/email",
          title: emailTitle,
          content: formData,
          additionalText: emailText,
        });
        if (!response.data.errorMessage) {
          setValidForm(true);
          window.dataLayer.push({'Category':'form','Action':'form submit','Label':'privacy policy','event':'auto_event'});
        } else {
          setErrorMsg("שליחת הטופס נכשלה.");
          setValidForm(false);
        }
        setIsLoader(false);
      } catch (error) {
        setIsLoader(false);
        setErrorMsg("שליחת הטופס נכשלה.");
        setValidForm(false);
      }
    } else {
      setValidForm(false);
      setErrorMsg("אנא וודא/י שכל שדות הטופס תקינים ומלאים");
    }
  };

  // preventing users from entering letters in number input fields
  const keyPressPrevent = (e: any, attribute: any) => {
    if (attribute.type === 'tel' || attribute.type === 'number') {
      if (!(/^\d$/).test(e.key) && e.key.length === 1) {
        e.preventDefault();
      }
    }
  }

  const sendEmail = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let validFields = true;
    checkRequired(e);
    inputAttributes.forEach((element) => {
      if (element.errorMsg !== "" || !confirmConditions) {
        validFields = false;
      }
    });
    checkFormValidation(validFields, e);
  };

  const checkRequired = async (
    form: FormEvent<HTMLFormElement>
    ) => {
    let allRequiredFilled = true;
    await inputAttributes.map((input) => {
      if (input.isRequired && !form.currentTarget[input.name].value) {
        input.errorMsg = "יש למלא שדה זה";
        allRequiredFilled = false;
      }
    });
    if (allRequiredFilled && !confirmConditions) {
      setErrorMsg("יש לסמן את התיבה למעלה לפני שליחת הטופס");
      return;
    }
  }

  return (
    <div className="form-container">
      <h2 className="fill-form">אנא מלאו את הפרטים הבאים בטופס </h2>
      <p className="must-text">שדות המסומנית בכוכבית (*) הם שדות חובה. </p>
      <div className="form-div">
        {!validForm ? (
          <form onSubmit={(e) => sendEmail(e)} ref={theForm}>
            {inputAttributes.map((attribute: FormConig) => {
              return (
                <div key={attribute.id}>
                  <Input
                    inputAttribute={attribute}
                    onChange={(e) => onFormChange(e, attribute)}
                    onKeyDown={(e) => keyPressPrevent(e, attribute)}
                  />
                </div>
              );
            })}
            
            <Condition setConfirmConditions={setConfirmConditions} />
            <div className="submit">
              {isLoader ? (
                <div className="loader"></div>
              ) : (
                <button className="submit-button" type="submit" aria-label="submit">
                  לשליחת ההסכמה שלי
                </button>
              )}
              <span className="invalid-feedback">{errorMsg}</span>
            </div>
          </form>
        ) : (
          <div className="form-submitted-successfully" id="form-submitted-successfully"
          aria-label="form-has-been-submitted">
            <h1>תודה על ההרשמה!</h1>
          </div>
        )}
        <CheckedCondition />
      </div>
    </div>
  );
};
