import { ChangeEvent } from "react";
import { Dispatch, SetStateAction } from "react";
import "./conditions.scss";

type ConditionProps = {
  setConfirmConditions: Dispatch<SetStateAction<boolean>>;
};

declare const window: any;
let firstTimeChecked = false;

export const Condition = ({ setConfirmConditions }: ConditionProps) => {
  const handelCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
    setConfirmConditions(e.target.checked);
    if (e.target.checked && !firstTimeChecked) {
      firstTimeChecked = true;
      window.dataLayer.push({'Category':'form','Action':'fill checkbox','Label':'privacy policy','event':'auto_event'});
    }
  };

  return (
    <div className="column">
      <div className="checkbox">
        <label htmlFor="condition1">
          <input
            name="condition1"
            id="condition1"
            aria-label="condition one"
            type="checkbox"
            onChange={(e) => handelCheckbox(e)}
          />
          הנני מאשר/ת בזאת כי קיבלתי וקראתי את המידע הכלול במסמך מדיניות הפרטיות
          ואני נותן/ת את הסכמתי להליך המתואר בו. בחתימה על טופס זה אני מאשר/ת כי
          התוכנית והשירותים הניתנים במסגרתה הוסברו לי על ידי אחות טבע. אני
          מסכים/ה לקבל הדרכה, הנחיות ותמיכה מאחות טבע כמתואר במסמך מדיניות
          הפרטיות. אני מסכים/ה לכך שטבע ו/או מי מטעמה יצרו איתי קשר במישרין
          בדואר, בטלפון או בדואר אלקטרוני לצרכי התוכנית ולקבלת מידע לגבי טיפול
          AJOVY<sup>®</sup> כמתואר במסמך מדיניות הפרטיות במהלך התכנית. אני מסכים/ה לכך שטבע
          ו/או מי מטעמה יצרו איתי קשר במישרין בדואר, בטלפון או בדואר אלקטרוני
          לצרכי דיווח על תופעות לוואי בכפוף להוראות הדין, לגבי טיפול באמצעות
          AJOVY<sup>®</sup> כמתואר במסמך מדיניות הפרטיות בין אם במהלך התוכנית ובין אם לאחר
          פרישתי מהתוכנית. אני מסכים/ה כי במקרה של דיווח על תופעת לוואי, ואם
          הדבר יידרש במפורש על-ידי משרד הבריאות, טבע תהא רשאית להעביר מידע מזוהה
          שלי למחלקת מעקב תרופתי בטבע ובמידת הצורך לפנות בעניין זה אל הרופא
          המטפל שלי.
        </label>
      </div>
    </div>
  );
};
