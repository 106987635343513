import { FormConig } from "../interfaces";

const strToNum = (num : string) => Number(num);

const isIdValid = (id: number): boolean => {
  let finalNum = 0;
  let intArr = Array.from(String(id), strToNum);
  intArr.forEach(function (num, index) {
    if (index % 2 !== 0) {
      num *= 2;
      if (num > 9) {
          let tempNumStr = String(num);
          num = parseInt(tempNumStr[0]) + parseInt(tempNumStr[1]);
      }
    }
    finalNum += num;
  });
  return (finalNum % 10 === 0 && finalNum !== 0);
}

export const dateToday = (): string => {
  let d = new Date();
  let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
  let mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
  let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
  return `${ye}-${mo}-${da}`;
}

const nameValidation = (name: string): string => {
  if (name.length < 2 || !/\S\s+\S/.test(name) || /^\s*$/.test(name)) {
    return ` נא לוודא שהזנת שם פרטי ושם משפחה`;
  }
  return "";
}

export const checkValidation = (input: FormConig, value: any) => {
  if (value === undefined) {
    return `שדה ${input.label} לא תקין`;
  }
  if (value.length === 0) {
    if (input.isRequired) {
      return "יש למלא שדה זה";
    }
    return;
  }
  
  switch (input.type) {
    case "number":
      if (input.name === "id") {
        if (!(/^[0-9]{9}$/.test(value)) || !isIdValid(value)) {
          return "מספר תעודת זהות לא תקין";
        }
      }
      break;
    case "tel":
      if (value.length < 10) {
        return ` יש להזין 10 ספרות`;
      }
      break;
    case "text":
      if (input.name === "name") {
        return nameValidation(value);
      }
      if (value.length < 2) {
        if (input.name === "address") {
          return ` כתובת לא תקינה`;
        }
        if (input.name === "city") {
          return ` עיר לא תקינה`;
        }
        return ` ${input.label} לא תקין`;
      }
      break;
    default:
      return "";
  }
};
