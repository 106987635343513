import { FormConig } from "../interfaces/index";
import { dateToday } from "../utils/fileUtils";

export const TreatmentProgramFields: FormConig[] = [
  {
    id: 1,
    name: "name",
    label: "שם מלא",
    type: "text",
    isRequired: true,
    beenFilled: false,
    errorMsg: "",
    maxLength: 30,
    minLength: 2,
  },
  {
    id: 2,
    name: "id",
    label: "מספר ת.ז",
    type: "number",
    isRequired: true,
    beenFilled: false,
    errorMsg: "",
  },
  {
    id: 3,
    name: "city",
    label: "עיר מגורים",
    type: "text",
    beenFilled: false,
    errorMsg: "",
    maxLength: 20,
    minLength: 2,
  },
  {
    id: 4,
    name: "address",
    label: "כתובת",
    type: "text",
    beenFilled: false,
    errorMsg: "",
    maxLength: 50,
    minLength: 2,
  },
  {
    id: 5,
    name: "postalCode",
    label: "מיקוד",
    type: "number",
    beenFilled: false,
    errorMsg: "",
    maxLength: 50,
    minLength: 2,
  },
  {
    id: 6,
    name: "phone",
    label: "טלפון אישי",
    type: "tel",
    isRequired: true,
    beenFilled: false,
    errorMsg: "",
    maxLength: 10,
    minLength: 10,
  },
  {
    id: 7,
    name: "birthday",
    label: "תאריך לידה",
    type: "date",
    isRequired: true,
    beenFilled: false,
    errorMsg: "",
    max: dateToday(),
  },
];
