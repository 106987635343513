import './App.scss';
import { TreatmentProgramForm } from './formPages/treatmentProgramPage';

function App() {
  return (
    <div className="App">
      <TreatmentProgramForm />
    </div>
  );
}

export default App;
