import "./conditions.scss";

export const CheckedCondition = () => {
  const nowDate = new Date();
  const date =
    nowDate.getDate() +
    "/" +
    (nowDate.getMonth() + 1) +
    "/" +
    nowDate.getFullYear();

  return (
    <div className="column">
      <div className="checkbox">
        <label className="disabled-checkbox-label" htmlFor="">
          <input
            className="disabled-checkbox"
            name="condition2"
            aria-label="condition two"
            type="checkbox"
            value=" אני מצהיר/ה בזאת שההסכמה לעיל התקבלה על ידי לאחר שהסברתי למטופל את כל האמור לעיל ולאחר שקיבלתי מהמטופל אישור לכך שהוא/היא הבין/ה את כל הסבריי האמורים."
            checked
            disabled
          />
          אני מצהיר/ה בזאת שההסכמה לעיל התקבלה על ידי לאחר שהסברתי למטופל את כל
          האמור לעיל ולאחר שקיבלתי מהמטופל אישור לכך שהוא/היא הבין/ה את כל
          הסבריי האמורים.
        </label>
      </div>
      <p>שם האחות: יפעת קולה</p>
      <p>תאריך: {date}</p>
    </div>
  );
};
